import React, { useState, useEffect } from "react";
import Typical from "react-typical";
import Switch from "react-switch";
import logo from './Javier_Sitting.jpg';

const Header = (props) => {
  const [checked, setChecked] = useState(false);
  const [count, setCount] = useState(0);

  const titles = props.sharedData ? props.sharedData.titles.map(x => [ x.toUpperCase(), 1500 ]).flat() : [];

  const onThemeSwitchChange = (checked) => {
    setChecked(checked);
    setTheme();
  };

  const setTheme = () => {
    var dataThemeAttribute = "data-theme";
    var body = document.body;
    var newTheme =
      body.getAttribute(dataThemeAttribute) === "dark" ? "light" : "dark";
    body.setAttribute(dataThemeAttribute, newTheme);
  };

  // useEffect(() => {
  //   const timer = setInterval(() => {
  //     setCount(prevCount => {
  //       if (prevCount < 100) {
  //         return prevCount + 1;
  //       } else {
  //         clearInterval(timer);
  //         return prevCount;
  //       }
  //     });
  //   }, 100);
  //   return () => clearInterval(timer);
  // }, []);

  const HeaderTitleTypeAnimation = React.memo( () => {
    return <Typical className="title-styles" steps={titles} loop={50} />;
  }, (props, prevProp) => true);

  const name = props.sharedData ? props.sharedData.name : '';

  return (
    <header id="home" style={{ height: "1000px", display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      <div className="row aligner" style={{height: '100%'}}>
        <div className="col-md-12">
          <div>
            {/* <span className="text-lg md:text-xl mt-4">
              Internships: {count}
            </span> */}
            <span className="header-icon">
              <img className="circle-icon" height="600px" src={logo} alt="Logo" />
            </span>
            {/* <span className="boxed-text text-lg md:text-xl mt-4">
              Projects: {count}
            </span>  */}
            <br/>
            <h1 className="mb-0">
              <Typical steps={[name]} wrapper="p" />
            </h1>
            <div className="title-container">
              <HeaderTitleTypeAnimation />
            </div>
            <Switch
              checked={checked}
              onChange={onThemeSwitchChange}
              offColor="#93C6E7"
              onColor="#353535"
              className="react-switch mx-auto"
              width={90}
              height={40}
              uncheckedIcon={
                <span
                  className="iconify"
                  data-icon="twemoji:owl"
                  data-inline="false"
                  style={{
                    display: "block",
                    height: "100%",
                    fontSize: 25,
                    textAlign: "end",
                    marginLeft: "20px",
                    color: "#353239",
                  }}
                ></span>
              }
              checkedIcon={
                <span
                  className="iconify"
                  data-icon="noto-v1:sun-with-face"
                  data-inline="false"
                  style={{
                    display: "block",
                    height: "100%",
                    fontSize: 25,
                    textAlign: "end",
                    marginLeft: "10px",
                    color: "#353239",
                  }}
                ></span>
              }
              id="icon-switch"
            />
          </div>
        </div>
      </div>
    </header>
  );
}

export default Header;

